// this is the place for your components / CSS
.btn {
  letter-spacing: .2em;
  text-transform: uppercase;
  font-weight: bold;
}


.selectpicker__control {
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-weight: bold;
  font-size: 0.8rem;
}


/* .react-dropdown-select {
  border-radius: 0.4rem;
  min-height: 38px;
  height: calc(1.6em + 0.75rem + 2px);
}
*/

.react-dropdown-select-content {
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-weight: bold;
  font-size: 0.8rem;
  cursor: pointer;
}

.react-dropdown-select-option {
  background-color: theme-color("primary") !important;
}

/*
.react-dropdown-select__menu {
  position: relative;
  z-index: 60 !important;
  font-size: 0.9rem;
  border-color: none !important;
  cursor: pointer;
}

.react-dropdown-select__control--menu-is-open,
.react-dropdown-select__control--is-focused {
  border-color: lighten(theme-color("primary"), 15%) !important;
  box-shadow: 0 0 0 $input-btn-focus-width $input-btn-focus-color !important;
} */



/*
* ===================================================
*    Custom Nav
* ===================================================
*/

.nav-pills.custom-nav {
  overflow: hidden;
  padding: 0;
  border: none;
  border-radius: 5px;
  box-shadow: $box-shadow;
}

.custom-nav .nav-item {
  background: $gray-100;
  align-self: stretch;

  &:last-of-type {
    .nav-link {
      border-right: none !important;

      &::after {
        display: none;
      }
    }
  }
}

.custom-nav .nav-link {
  position: relative;
  height: 100%;
  //  padding-top: 1.2rem;
  // padding-bottom: 1.2rem;
  text-align: center;

  color: $gray-900;
  border: none !important;
  border-radius: 0 !important;
  background: none;
  background: none;

  &.active {
    color: #fff;
    background: theme-color('primary');

    &::after {
      display: none;
    }
  }

  &.disabled {
    cursor: not-allowed !important;
    color: $gray-500;
    background: $gray-100;
  }

  &::after {
    display: inline-block;
    position: absolute;
    top: 25%;
    right: 0;
    width: 1px;
    height: 50%;
    content: '';
    background: #ddd;
  }
}

/* Checkout Media Query -------------------- */

@include media-breakpoint-down(md) {
  .custom-nav .nav-item {
    width: 100% !important;
    margin-bottom: 5px;

    .nav-link {
      border-right: none;

      &::after {
        display: none;
      }
    }
  }
}

.container {
  position: relative;
}

.search {
  border: 1px solid #666;
  box-sizing: border-box;
  font-size: 18px;
  padding: 18px;
  width: 100%;
}

.results {
  list-style: none;
  overflow: hidden;
  margin: 9px 0 0;
  padding: 0;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
}

.result {
  ///border-radius: 90%;
  border: $btn-border-width solid transparent;
    border-color:  #0041ff;
  background: #0041ff;
  color: #eee;
  margin: 0 0 9px;
  padding: 18px;
}

.result a {
  color: #eee;
}

// Square overall score button
.score-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.43rem;
  height: 3.43rem;
}

// Top Companies table borders
.table-custom-border {
  tbody th,
  th:not(:last-of-type),
  td:not(:last-of-type) {
    border-right: 1px solid var(--bs-border-color);
  }
}


/*
* ===================================================
*    Homepage categories blured card
* ===================================================
*/
.blured-card {
  overflow: hidden;
  height: 100%;
  min-height: 460px;

  &.text-white .dark-overlay::after {
    opacity: 0;
    transition: opacity;
    transition-duration: 250ms;
  }

  &.text-white:hover .dark-overlay::after {
    opacity: 0.3;
  }

  &:hover img {
    filter: blur(4px);
    transition-property: filter, -webkit-filter;
    transition-timing-function: linear;
    transition-duration: 150ms;
  }

  .dark-overlay {
    width: calc(100% + 12px);
    position: absolute;
    height: calc(100% + 12px);
    margin: -6px;
    opacity: 1;

    &::after {
      opacity: 0;
    }
  }

  li a {
    font-weight: 500;
  }

  a {
    position: relative;
    color: inherit;
    text-decoration: none;
  }

  a::after {
    border-bottom: 1px solid;
    bottom: 0;
    content: "";
    display: block;
    left: 0;
    position: absolute;
    transition-duration: 550ms;
    transition-property: width;
    transition-timing-function: cubic-bezier(0.85, 0, 0, 1);
    width: 0;
  }

  a:hover::after {
    width: 100%;
  }
}